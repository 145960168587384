import logo from "../../assets/img/svg/logo.svg";
import Navigation from '../../Navigation';
import { useState, useEffect, useCallback, useRef } from "react";

const query = `
{
  navigationCollection {
    items {
      home
      offerte
      formule
      gallerij
      contact
    }
  }
}
`;

const Contact = () => {
  const [page, setPage] = useState(null);


  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setPage(data);
      });
  }, []);

  if (!page) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <main>
      <div className="container">
        <div className="row align-items-center pb-xlarge">
          <div className="col-12 col-md-6">
            <a href="/">
              <figure>
                <img src={logo} className='site-logo' alt="Boefferke" />
              </figure>
            </a>
            </div>
            <div className="col-12 col-md-6">
              <Navigation
                navProps={page.navigationCollection.items[0]}
              ></Navigation>
            </div>
          </div>
          <div className="row pb-xlarge">
            <div className="col-12">
                <div className="card">
                    <div className="card__content">
                        <h2>Boefferke</h2>
                        <p>Mobiele frituur, snacks en BBQ</p>

                        <p> Adres:<br />
                            Nijverheidstraat 25<br />
                            8791 Beveren-Leie<br />
                        </p>
                        <p>
                            Telefoon:<br />
                            056 71 37 87
                        </p>
                        <p>
                            E-mail:<br />
                            <a href="mailto:info@boefferke.be">info@boefferke.be</a>
                        </p>
                        <p>
                            BTW-nummer:<br />
                            BE 0537 301 113
                        </p>
                        <p>
                            Algemene voorwaarden:<br />
                            <a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">download de algemene voorwaarden</a>
                        </p>                       
                    </div>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <footer className='footer'>
              <div>
                  <p>&copy; 2023 Boefferke. All rights reserved.</p>
              </div>
              
              <div className='d-flex'>
                  <ul className='menu-list'>
                      <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
                  </ul>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </main>
  )
  }
  
  export default Contact
  