import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
        <div id="error-page">
          <h1>Oops!</h1>
          <p>Sorry, je kunt jammer genoeg nog niet smullen van deze pagina!</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
        </div>
      </div>
    </div>
  );
}