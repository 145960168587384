import { useState, useEffect } from "react";

const Navigation = ({ navProps }) => {
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'app-dark'
  );
  const toggleTheme = () => {
    if (theme === 'app-light') {
      setTheme('app-dark');
    } else {
      setTheme('app-light');
    }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    if (theme === 'app-light') {
      document.documentElement.setAttribute('data-bs-theme','light')
    } else {
      document.documentElement.setAttribute('data-bs-theme','dark')
    }
  }, [theme]);

  return (
    <>
      <ul className="block-navigation">
        <li>
          <a href={navProps.home}>Home</a>
        </li>
        <li>
          <a href={navProps.offerte}>Offerte</a>
        </li>
        <li>
          <a href={navProps.formule}>Formule</a>
        </li>
        <li>
          <a href={navProps.gallerij}>Gallerij</a>
        </li>
        <li>
          <a href={navProps.contact}>Contact</a>
        </li>
        <button className="btn btn--green" onClick={toggleTheme}>Verander Thema</button>
      </ul>
    </>
  )
}

export default Navigation
