import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/main/_all.scss'
import App from './App'
import Offerte from "./pages/components/Offerte";
import Formules from "./pages/components/Formules";
import GalleryPage from "./pages/components/GalleryPage";
import ErrorPage from "./pages/components/ErrorPage";
import Contact from "./pages/components/Contact";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import OfferHiddenPage from './pages/components/OfferHiddenPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "bbq-all-in-formules-offerte",
    element: <OfferHiddenPage />,
  },
  {
    path: "frietformules-offerte",
    element: <OfferHiddenPage />,
  },
  {
    path: "frietmenu-voor-grote-groepen",
    element: <OfferHiddenPage />,
  },
  {
    path: "partyformules",
    element: <OfferHiddenPage />,
  },
  {
    path: "pastaformules",
    element: <OfferHiddenPage />,
  },
  {
    path: "offerte",
    element: <Offerte />,
  },
  {
    path: "formule",
    element: <Formules />,
  },
  {
    path: "gallerij",
    element: <GalleryPage />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <RouterProvider router={router} />
    <CookieConsent
        location="bottom"
        buttonText="Smullen maar!!"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
  </>
);