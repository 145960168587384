import logo from "../../assets/img/svg/logo.svg";
import Navigation from '../../Navigation';
import FormuleSoort from "./FormuleSoort";
import useSWR from 'swr';
import { useState, useEffect, useCallback, useRef } from "react";
import { Spinner } from '@contentful/f36-spinner';
import { createClient } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Carousel, { Modal, ModalGateway } from "react-images";
import Aos from 'aos';
import MatchHeight from 'matchheight';
import Gallery from "react-photo-gallery";

const query = `
{
  navigationCollection {
    items {
      home
      offerte
      formule
      gallerij
      contact
    }
  }
  gallerijFotoCollection {
    items {
      gallerijFotoTitel
      afbeelding1
    }
  }
}
`;

const GalleryPage = ({ formuleSoort, parentState, parentStateSetter }) => {
  const [page, setPage] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  Aos.init();


  useEffect(() => {
    let runCounter = 0;

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.CONTENTFUL_DELIVERY_TOKEN}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        setPage(data);
        if (data && !page) {
            [...data.gallerijFotoCollection.items].forEach((item) => {
                let photoObject = {
                    src : item.afbeelding1[0].url,
                    width: randomIntFromInterval(1, 2),
                    height: randomIntFromInterval(1, 2)
                }
                setPhotos(photos => [...photos,photoObject]);
            })
        }
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      swapItem();
    }, 500);
  }, [photos]);

  function swapItem() {
    let imgItemSrcBoolAll = document.querySelectorAll('.galery img');
    console.log(imgItemSrcBoolAll);
    imgItemSrcBoolAll.forEach(item => {
      let containsVideo = item.src.includes('.mp4')
      let containsVideo2 = item.src.includes('.mov')
      if (item && containsVideo || containsVideo2) {
        let imgItemSrcBool = containsVideo;
        let imgItemSrc = item.src;
        let imgItem = item;
  
        let video = document.createElement('video');
        let source = document.createElement('source');
        source.src = imgItemSrc;
        source.type = 'video/mp4';
        video.src = imgItemSrc;
        video.autoplay = true;
        video.controls = true;
        video.muted = true;
        item.parentElement.prepend(video);
        item.remove();
      }
    });
  }

  function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  if (!page) {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <main>
      <div className="container">
        <div className="row align-items-center pb-xlarge">
          <div className="col-12 col-md-6">
            <a href="/">
              <figure>
                <img src={logo} className='site-logo' alt="Boefferke" />
              </figure>
            </a>
            </div>
            <div className="col-12 col-md-6">
              <Navigation
                navProps={page.navigationCollection.items[0]}
              ></Navigation>
            </div>
          </div>
          <div className="row pb-xlarge">
            <div className="col-12 galery">
              {photos.map(x => {
                x.src.includes('.mp4') ?
                  <video src={x.src}></video>
                :
                null
              })}
              
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                        currentIndex={currentImage}
                        views={photos.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                        }))}
                        />
                    </Modal>
                    ) : null}
                </ModalGateway>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
            <footer className='footer'>
              <div>
                  <p>&copy; 2023 Boefferke. All rights reserved.</p>
              </div>
              
              <div className='d-flex'>
                  <ul className='menu-list'>
                      <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
                  </ul>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </main>
  )
  }
  
  export default GalleryPage
  