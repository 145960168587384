import { useState, useEffect, useRef } from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const FormuleSoort = ({ formuleSoort, parentState, parentStateSetter }) => {
    const [soort, setSoort] = useState(formuleSoort);
    const childRef = useRef();
    const [childState, setChildState] = useState('');
    
    const changeActiveSort = e => {
        e.preventDefault();
        if (parentState !== soort.formuleSoortTitel) {
            setChildState(soort.formuleSoortTitel);
            parentStateSetter(soort.formuleSoortTitel);
        }
    };

    return (
      <div className="col-12" data-aos="fade-up" data-aos-anchor-placement="top-bottom" >
        <button ref={childRef} className={`btn--type btn${parentState === childState && childState !== '' ? " btn--active" : ""}`} onClick={changeActiveSort}>
            <div className="row align-items-center">
                {soort !== undefined && soort.formuleSoortAfbeelding && 
                    <div className="col-12 col-md-6">
                        <img src={soort.formuleSoortAfbeelding.url} alt={soort.formuleSoortTitel} />
                    </div>
                }
                <div className="col-12">
                    {soort !== undefined && soort.formuleSoortTitel && 
                        <h3>{soort.formuleSoortTitel}</h3>
                    }
                </div>
            </div>
        </button>
      </div>
    )
  }
  
  export default FormuleSoort
  