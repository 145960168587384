import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { createClient } from 'contentful';
import { useState, useEffect } from 'react';
import Formule from './Formule';

const FormulesOverview = ({ formules }) => {
  let allFormules = [];
  const [ apiResponse, setApiResponse ] = useState('');
  const [ productName, setProductName ] = useState('');
  const [ productDescription, setProductDescription ] = useState('');
  const [ productImage, setProductImage ] = useState('');

  function createMarkup() {
    return {
       __html: documentToHtmlString(formules[0].fields.intro) };
  };

  const client = createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN,
  })

  return (
    <>
      <div className="col-12">
        <div className='alert pb-xlarge' dangerouslySetInnerHTML={createMarkup()} />
      </div>
      {formules[0].fields.formule.map((item, i) => {
        return (
          <Formule
            key={i}
            entryId={item}
          ></Formule>
      )
    })}
    </>
  )
}

export default FormulesOverview
