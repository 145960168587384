import React, { useEffect, useState } from 'react';
import logo from "../../assets/img/svg/logo.svg";
import '../../styles/main/_all.scss'

export default function OfferHiddenPage() {
  const [isAdmin, setIsAdmin] = useState(null);
  const [widget, setWidget] = useState(null);
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'app-dark'
  );

  function createMarkup() {
    return {
       __html: widget };
  };

  const toggleTheme = () => {
    if (theme === 'app-light') {
      setTheme('app-dark');
    } else {
      setTheme('app-light');
    }
  };
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    if (theme === 'app-light') {
      document.documentElement.setAttribute('data-bs-theme','light')
    } else {
      document.documentElement.setAttribute('data-bs-theme','dark')
    }
  }, [theme]);

  useEffect(() => {
    if (window.localStorage.getItem('adminBoefferEmile')) {
      // Append the CSS file to the document head
      const link = document.createElement('link');
      link.href = 'https://static.catermonkey.com/cm-shop-style-v2.css';
      link.rel = 'stylesheet';
      link.type = 'text/css';
      document.head.appendChild(link);

      // Set the CaterMonkey settings
      window.catermonkeySettings = {
        token: 'bdd715340b35'
      };

      // Append the JS file and add the widget div
      const script = document.createElement('script');
      script.src = 'https://static.catermonkey.com/cm-shop.js';
      script.type = 'text/javascript';
      document.body.appendChild(script);

      setWidget()
      setIsAdmin(true);
    }
  }, []);

  return (
    isAdmin ? 
    <main>
      <div className="row align-items-center pb-xlarge">
        <div className="col-12 col-md-6">
        <a href="/">
          <figure>
            <img src={logo} className='site-logo' alt="Boefferke" />
          </figure>
        </a>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center pb-xlarge">
          <div className="col-12">
            <div id="catermonkey-shop"></div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <footer className='footer'>
                  <div>
                      <p>&copy; 2023 Boefferke. All rights reserved.</p>
                  </div>
                  
                  <div className='d-flex'>
                      <ul className='menu-list'>
                          <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Algemene voorwaarden</a></li>
                      </ul>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main> :
    <p>Sorry, je kunt jammer genoeg nog niet smullen van deze pagina!</p>
  );
}