import FormulesOverview from './FormulesOverview'
import Navigation from './Navigation'
import logo from "./assets/img/svg/logo.svg";

const HomeInfo = ({ name, info, nav, img, imgHead, imgDesc, formulesOverview, moreInfo }) => {
  function createMarkup() {
    return {
       __html: info };
  };

  function createMarkupImgHead() {
    return {
       __html: imgHead };
  };

  function createMarkupImgDesc() {
    return {
       __html: imgDesc };
  };

  function createMarkupMoreInfo() {
    return {
       __html: moreInfo };
  };

  return (
    <>
      <h1 className='visually-hidden'>{name}</h1>
      <div className="row align-items-center pb-xlarge">
        <div className="col-12 col-md-6">
        <a href="/">
          <figure>
            <img src={logo} className='site-logo' alt="Boefferke" />
          </figure>
        </a>
        </div>
        <div className="col-12 col-md-6">
          <Navigation
          navProps={nav}
          ></Navigation>
        </div>
      </div>
      <div className="row pb-xlarge">
        <div className="col-12">
          <div className="block-image">
            <div data-aos="fade-up" data-aos-anchor-placement="center-center">
              <div className='block-image__content'>
                {imgHead ? <div className='block-image__content__header' dangerouslySetInnerHTML={createMarkupImgHead()} />: null }
                {imgDesc ? <div className='block-image__content__description' dangerouslySetInnerHTML={createMarkupImgDesc()} />: null }
              </div>
              <div className="block-image__image__wrapper">
                <div className='block-image__image__overlay'></div>
                <figure>
                  <img src={img.fields.file.url} className='block-image__image' alt="Girl in a jacket" width={img.fields.file.details.image.width} height={img.fields.file.details.image.height} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-xlarge">
        <div className="col-12">
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
              <div dangerouslySetInnerHTML={createMarkup()} />
          </div>
        </div>
      </div>
      <div className="row pb-xlarge">
        <div className="col-12">
          <div data-aos="fade-up" data-aos-anchor-placement="top-bottom">
            <div className="row gy-4 gx-4">
              <FormulesOverview
                formules={formulesOverview}
              ></FormulesOverview>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-xlarge">
        <div className="col-12">
          <div data-aos="fade-up" data-aos-anchor-placement="center-bottom">
              <div dangerouslySetInnerHTML={createMarkupMoreInfo()} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
        <footer className='footer'>
          <div>
              <p>&copy; 2023 Boefferke. All rights reserved.</p>
          </div>
          
          <div className='d-flex'>
              <ul className='menu-list'>
                  <li><a href="https://boefferke.be/public_files/Algemene-voorwaarden.pdf">Voorwaarden</a></li>
              </ul>
          </div>
        </footer>
        </div>
      </div>
    </>
  )
}

export default HomeInfo
